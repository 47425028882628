const Pages = [
  { name: ``, url: '/', permissions: [], },
  { name: `メインページ`, url: '/mainpage', permissions: [], },
  { name: `発注データ一括送信`, url: '/orderinstruction', permissions: [], },
  { name: `メーカー発注設定`, url: '/settingmaker', permissions: [], },
  { name: `商品発注詳細設定`, url: '/settingitem', permissions: [], },
  { name: `メーカー残在庫設定`, url: '/settinglimit', permissions: [], },
  { name: `センター発注設定`, url: '/settingcenter', permissions: [], },
  { name: `転送依頼設定`, url: '/settingtransfercenter', permissions: [], },
  { name: `タグ設定`, url: '/settingtags', permissions: [], },
];

export const checkPermission = (url: string, permissions: string[]) => {
  if (!Array.isArray(permissions)) {
    console.log(permissions);
    permissions = [];
  }
  const checkUrl = url
    .replace(/\?.*$/, "") // ?以降を削る
  //パスの位置+パスの長さ＝URLの長さ
  const page = Pages.find(page => checkUrl.lastIndexOf(page.url) + page.url.length == checkUrl.length);
  // console.log("checkPermission", url, checkUrl, permissions, page);
  if (!page) {
    return false;
  }
  return page.permissions.every(permission => permissions.includes(permission));
}
